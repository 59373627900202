let initedSwiper = false;
let swiper = null;

function initSwiper() {
  if (window.innerWidth <= 768) {
    if (!initedSwiper) {
      initedSwiper = true;
      swiper = new Swiper('.swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
    }
  } else if (initedSwiper)  {
    swiper.destroy();
    initedSwiper = false;
  }
}

let initedAccordion = false;
let accordion = null;

function initAccordion() {
  if (window.innerWidth <= 1200) {
    if (!initedAccordion) {
      initedAccordion = true;
      accordion = new Accordion('.accordion-header', {
        duration: 300,
        triggerClass: 'header__menu-burger',
        beforeOpen: () => {
          if (document) document.body.classList.add('fixed');
        },
        beforeClose: () => {
          if (document) document.body.classList.remove('fixed');
        }
      });
    }
  } else if (initedAccordion)  {
    accordion.destroy();
    initedAccordion = false;
  }
}

initSwiper();
initAccordion();
window.addEventListener("resize", initSwiper);
window.addEventListener("resize", initAccordion);

new Accordion('.accordion-container');
